<template>
    <div class="col-12 text-center">
        <div class="maximum-width">
            <template v-if="notEnoughData || netellerDepositError">
                <!--Error-->
                <error></error>
                <p v-if="notEnoughData" class="alert alert-danger mt-5 text-center">{{$t('not-enough-data')}}</p>
                <p v-else-if="netellerDepositError" class="alert alert-danger mt-5 text-center">{{netellerDepositError}} {{$t('request-unsuccessful')}}</p>
            </template>
            <div v-else-if="showLoader">
                <div class="text-center mt-5">
                    <div class="spinner-border spinner-border-sm ml-auto" role="status" aria-hidden="true"></div><br/>
                </div>
            </div>
            <div v-else-if="accountInvalid || (limitReached && maxDeposit > 0)">
                <div class="col-12 text-center"><img src="../../../assets/merchant-logos/neteller.png"></div>
                <form @submit.prevent="submit" novalidate>
                    <div v-if="limitReached">
                        <limits-amount v-on:playerAmount="playerAmount" :amount="amount" :playerCurrencySymbol="playerCurrencySymbol" :limitReached="limitReached" :minDeposit="minDeposit" :maxDeposit="maxDeposit"></limits-amount>
                    </div>
                    <div v-else-if="accountInvalid">
                        <div class="form-group floating-input">
                            <input type="text" class="form-control" id="netellerEmail" v-model.trim="$v.email.$model" :placeholder="$t('email')" required>
                            <label for="netellerEmail">{{$t('email')}}</label>
                        </div>
                        <div class="validation-error">
                            <label v-if="!$v.email.required && $v.email.$dirty">{{$t('please-enter-your', { field: $t('email') })}}</label>
                            <label v-else-if="!$v.email.email && $v.email.$dirty">{{$t('invalid-field', {field: $t('email')} ) }}</label>
                        </div>

                        <p class="alert alert-danger mt-3 text-center" v-if="accountInvalid">{{accountInvalid}}</p>
                    </div>
                    <button class="btn btn-success col-12 mt-3" :class="{'disabled':submitStatus === 'PENDING'}" v-if="accountInvalid || (limitReached && maxDeposit > 0)">
                        <div v-if="submitStatus !== 'PENDING'"><strong>{{$t('submit')}}</strong></div>
                        <div v-else class="d-flex align-items-center">
                            <strong>{{$t('loading')}}...</strong>
                            <div class="spinner-border spinner-border-sm ml-auto" role="status" aria-hidden="true"></div>
                        </div>
                    </button>
                </form>
            </div>
            <div v-if="limitReached && maxDeposit <= 0">
                <p class="alert alert-danger mt-3 text-center" v-if="limitReached">{{limitReached}}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import {getProviderUrl} from "../../../services/backend.service";
    import {validationMixin} from 'vuelidate';
    import {required, email} from 'vuelidate/lib/validators';

    export default {
        name: 'NetellerDeposit',
        data() {
            return {
                showLoader:true,
                notEnoughData: null,
                netellerDepositError: null,
                language: null,
                customerId: null,
                ipAddress: null,
                brandUrl: null,
                playerCurrency: null,
                playerCurrencySymbol: null,
                minDeposit: null,
                maxDeposit: null,
                limitReached: null,
                amount: null,
                accountInvalid: null,
                email: null,
                submitStatus: null,
            }
        },
        components: {
            Error: () => import('../../others/Error'),
            LimitsAmount: () => import('../../others/LimitsAmount'),
        },
        mixins: [validationMixin],
        validations() {
            return {
                email:{
                    required,
                    email
                }
            }
        },
        mounted() {
            this.language = this.$router.history.current.query.language;
            this.customerId = this.$router.history.current.query.customerId;
            this.ipAddress = this.$router.history.current.query.ipAddress;
            this.playerCurrency = this.$router.history.current.query.playerCurrency;
            this.playerCurrencySymbol = this.$router.history.current.query.playerCurrencySymbol;
            this.amount = this.$router.history.current.query.amount;
            this.brandUrl = this.$router.history.current.query.brandUrl;

            if(!this.customerId || !this.ipAddress || !this.playerCurrency || !this.amount || !this.playerCurrencySymbol){
                this.notEnoughData = true;
            } else {
                this.redirectToNeteller();
            }
        },
        methods: {
            redirectToNeteller(fromSubmit) {
                const amount = fromSubmit ? this.amount * 100 : this.amount;
                getProviderUrl({
                    currency: this.playerCurrency,
                    amount: amount,
                    processorName: "PAYSAFE",
                    processorType: "EWALLET",
                    customerId: this.customerId,
                    ipAddress: this.ipAddress,
                    language: this.language,
                    accountNumber: this.email,
                    brandUrl: this.brandUrl,
                }).then(res => {
                    this.showLoader = false;
                    this.submitStatus = null;
                    if (res.data && res.data.success && res.data.data && res.data.data.redirectUrl) {
                        window.location.replace(res.data.data.redirectUrl);
                    } else if(res.data && res.data.success && res.data.data && res.data.data.minLimitAmount !== null && res.data.data.maxLimitAmount !== null && res.data.data.errorCode && res.data.data.errorCode !== 'account-invalid') {
                        this.minDeposit = Math.floor(res.data.data.minLimitAmount/100);
                        this.maxDeposit = Math.floor(res.data.data.maxLimitAmount/100);
                        this.amount = Math.floor(res.data.data.maxLimitAmount/100);
                        this.limitReached = this.$t(res.data.data.errorCode);
                    } else if(res.data && res.data.success && res.data.data && res.data.data.errorCode && res.data.data.errorCode === 'account-invalid'){
                        this.accountInvalid = this.$t(res.data.data.errorCode);
                    } else {
                        this.netellerDepositError = this.$t('error-2');
                    }
                }).catch(e => {
                    this.showLoader = false;
                    this.netellerDepositError = this.$t('error-2');
                    console.log(`%c Error in NetellerDeposit.vue - method: redirectToNeteller - ${e}`, 'color:red');
                });
            },
            submit() {
                this.$v.$touch();
                if((this.limitReached && this.minDeposit && this.maxDeposit && this.amount) || (this.accountInvalid && this.$v.email.$error === false)){
                    this.submitStatus = 'PENDING';
                    this.redirectToNeteller(true);
                }
            },
            playerAmount(value){
                this.amount = value;
            }
        }
    }
</script>
